import BookingService from '@/services/booking';

const DefaultType = 'week'

const DefaultWaitingTime = 3600000;  // equal 1 Hour

const TypeLabel = {
    month: 'Month',
    week: 'Week',
    day: 'Day',
    '4day': '4 Days'
};

const ListDefaultType = [
   { 
        title: 'Day',
        value: 'day'
    },

    {
        title: 'Week',
        value: 'week'
    },
    { 
        title: 'Month',
        value: 'month'
    },
    { 
        title: '4 days',
        value: '4day'
    }

];

const state = () => ({
    listDefaultType: ListDefaultType,
    type: DefaultType,
    typeToLabel: TypeLabel[DefaultType],
    titleCalendar: '',
    events: [],

    clinicID: '',
    keySearch: '',
    status: 1,
    page: 1,
    limit: 100,

    defaultRangeTime: DefaultWaitingTime,
    createNewBooking: false,
    timeStartForCreate: '',
    dateVisitForCreate: '',

    timeStart: '',
    timeEnd: '',

    removeLoading: false,

    status_req: {
        error: false,
        message: ''
    }
})

const mutations = {
    SET_DEFAULT_STATE: (state) => {
        state.type = DefaultType;
        state.typeToLabel = TypeLabel[DefaultType];
        state.titleCalendar = '';
        state.events = [];
        state.clinicID = '';
        state.keySearch = '';
    },
    SET_TYPE: (state, payload) => {
        state.type = payload.type
        state.typeToLabel = TypeLabel[state.type]
    },
    SET_EMPTY_FOCUS: () => { },        

    SET_TITLE_CALENDAR: (state, payload) => {
        state.titleCalendar = payload.title
    },

    NEXT_TIME: () => { },
    PREV_TIME: () => { },

    SET_CLINIC: (state, payload) => {
        state.clinicID = payload.clinicID
    },

    SET_KEYSEARCH: (state, payload) => {
        state.keySearch = payload.keySearch
    },
    LOAD_CALENDAR: (state) => {
        // default set type = week
        state.type = 'week';
        state.typeToLabel = TypeLabel[state.type];
        
    },
    SET_EVENTS: (state, payload) => {
        state.events = payload.events;
    },
    PUSH_EVENT: (state, payload) => {
        state.events.push(payload.event);
    },
    SET_TIME: (state, payload) => {
        let { start, end } = payload;
        state.startTime = start;
        state.endTime = end;
    },
    OPEN_DIALOG: () => {},
    SET_NEW_BOOKING: (state, payload) => {
        state.createNewBooking = payload.createNew;
    },
    SET_TimeForCreate: (state, payload) => {
        state.timeStartForCreate = payload.timeStart;
        state.dateVisitForCreate = payload.dateVisit;
    },
    REMOVE_NEW_ORDER_BOOKING: (state) => {
        state.events = state.events.filter(i => i.bookingID != null);
    },
    SHOW_ERROR: (state, payload) => {
        state.status_req = {
            error: payload.error,
            message: payload.message
        }
    }
}

const actions = {
    GET_BOOKING: async ({ commit, state }, time) => {
        let start, end;
       
        start = time.timeStart;
        end = time.timeEnd;
        
        // update state time
        state.timeStart = time.timeStart;
        state.timeEnd = time.timeEnd;

        let searchingResult = await BookingService.searchBookings(
            state.keySearch,
            start,
            end,
            state.clinicID,
            1, // active
            state.page,
            state.limit
        );
        if (searchingResult.error) {
            commit('SHOW_ERROR', {
                message: 'Can not get a booking list!',
                error: true
            });
            return;
        }
       
        // mapping record
        let dataResult = searchingResult.items.map(item => {
            let endTime = new Date(item.timeStart).getTime() + DefaultWaitingTime;
            let dataItem = {
                name: `${item.fullName}`,
                title: 'Patient Booking',
                bookingID: item.bookingID,
                color: '#64B5F6',
                start: new Date(item.timeStart),
                end: new Date(endTime),
                timed: true,
                detailBooking: item
            }
            return dataItem;
        })
        
        // call commit to store data 
        commit('SET_EVENTS', { events: dataResult })
         // update error = false
        commit('SHOW_ERROR', {
            message: '',
            error: false
        })
    },

    REMOVE_ITEM_BOOKING: async ({ state, commit }, payload) => {
        state.removeLoading = true;
        // call service to remove bookingID
        var removeBooking = await BookingService.deleteBooking(payload.bookingID);
        if (removeBooking.error) {
            commit('SHOW_ERROR', {
                message: 'Can not remove a booking item!',
                error: true
            });
            return;
        }
        state.events = state.events.filter(i => i.bookingID != payload.bookingID);
        state.removeLoading = false;
        // update error = false
        commit('SHOW_ERROR', {
            message: '',
            error: false
        })
    },

    UPDATE_BOOKING: async ({ state, commit }, payload) => {
    
        const { bookingID, timeStart, timeEnd, originalStart, originalEnd } = payload;
     
        let bodyReq = { timeStart, timeEnd };
        let resultUpdate = await BookingService.updatePatientBookingInfoByID(bookingID, bodyReq);
        if (resultUpdate.error) {
            
            // update with original Time
            state.events = state.events.map(i => {
                if (i.bookingID == bookingID) {
                    i.start = new Date(originalStart);
                    i.end = new Date(originalEnd);
                }
                return i;
            })
            commit('SHOW_ERROR', {
                message: 'Can not update a booking item!',
                error: true
            });
            return;
        }
        // update state events
        state.events = state.events.map(i => {
            if (i.bookingID == bookingID) {
                i.start = new Date(timeStart);
                i.end = new Date(timeEnd);
            }
            return i;
        })
        // update error = false
        commit('SHOW_ERROR', {
            message: '',
            error: false
        })
    }
}

export default {
    namespaced: 'BookingCalendar',
    state,
    mutations,
    actions
}