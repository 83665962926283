import AppConstant from '../plugins/constant';
import Helper from '../plugins/helper';

const state = () => ({
    tokenID: Helper.getLocalStorage(AppConstant.TokenIDCookieName),
    userData: Helper.parseLocalStorage(AppConstant.UserDataLocalStorageName),
})

const getters = {
    userData: state => state.userData,
    isAuthenticated: state => !!state.tokenID,
    isRoot: state => state.userData && state.userData.userTypeID == AppConstant.UserTypeCodes.Root,
    isAdmin: state => state.userData && state.userData.userTypeID == AppConstant.UserTypeCodes.Admin,
    isDoctor: state => state.userData && state.userData.userTypeID == AppConstant.UserTypeCodes.Doctor,
    isOptomologist: state => state.userData && state.userData.userTypeID == AppConstant.UserTypeCodes.Optomologist,
    isNurse: state => state.userData && state.userData.userTypeID == AppConstant.UserTypeCodes.Nurse,
    isReceptionist: state => state.userData && state.userData.userTypeID == AppConstant.UserTypeCodes.Receptionist,
}

const mutations = {
    SET_ACCESS_TOKEN: (state, tokenID) => {
        state.tokenID = tokenID;
        Helper.setLocalStorage(AppConstant.TokenIDCookieName, tokenID);
    },
    SET_USER_DATA: (state, userData) => {
        state.userData = userData;
        Helper.setLocalStorage(AppConstant.UserDataLocalStorageName, JSON.stringify(userData));
    },
    CLEAR_ACCESS_TOKEN: (state) => {
        state.tokenID = null;
        Helper.removeLocalStorage(AppConstant.TokenIDCookieName);
    },
    CLEAR_USER_DATA: (state) => {
        state.userData = null;
        Helper.removeLocalStorage(AppConstant.UserDataLocalStorageName);
    }
}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}