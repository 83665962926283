<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
  </v-app>
</template>

<script>
import '@progress/kendo-theme-default/dist/all.css';
export default {
  name: "App",
  components: {},
};
</script>
