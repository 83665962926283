export default {
  Common: {
    Allergies: 'Dị ứng',
    Address: 'Địa chỉ',
    Checking: 'Đang kiểm tra...',
    ViewChecking: 'Xem dữ liệu tìm được',
    ChiefComplains: 'Khiếu nại',
    ChronicDiseases: 'Bệnh mãn tính',
    CurrentInsuranceCard: 'Thẻ bảo hiểm hiện tại',
    Country: 'Quốc gia',
    DOB: 'Ngày sinh*',
    Email: 'Email',
    Ethnicity: 'Dân tộc',
    FamilyHistory: 'Tiểu sử gia đình',
    FullName: 'Họ và tên*',
    Status: 'Trạng thái',
    SocialInsuranceInformation: 'Thông tin bảo hiểm xã hội',
    HealthInsurrance: 'Bảo hiểm y tế',
    Insurrance: 'Bảo hiểm',
    HistoryExamination: 'Lịch sử kiểm tra',
    IdentificationNumber: 'Số CMND',
    Insurance: 'Bảo hiểm',
    InsuranceType: 'Loại bảo hiểm',
    InsuranceNumber: 'Số bảo hiểm',
    InsuranceCompany: 'Công ty bảo hiểm',
    MedicalConditionInformation: 'Thông tin tình trạng y tế',
    Note: 'Ghi chú',
    Occupation: 'Nghề nghiệp',
    PersonalBehaviour: 'Hành vi cá nhân',
    PersonalInformation: "Thông tin cá nhân",
    PersonalHistory: 'Tiểu sử cá nhân',
    RelativeInformation: 'Thông tin người thân',
    RelativeMemberName: "Tên người thân",
    Relationship: 'Mối quan hệ',
    TransferAnotherInsuranceCard: 'Chuyển thẻ bảo hiểm khác',
    PatientRegistration: 'Đăng ký bệnh nhân',
    OnlineBooking: 'Lịch hẹn trực tuyến',
    PatientQueue: 'Hàng chờ bệnh nhân',
    BillingQueue: 'Hàng chờ thanh toán',
    Report: 'Báo cáo',
    Dashboard: 'Giao diện người dùng',
    DoctorRoster: 'Bảng phân công bác sĩ',
    LastVisitsOf: 'Những lần khám của',
    LastVisit: 'Lần khám',
    PrimaryDiagnosis: 'Chẩn đoán chính',
    Male: 'Nam',
    Female: 'Nữ',
    Controller: 'Bộ điều khiển',
    QueueInformation: 'Thông tin hàng chờ',
    InProcess: 'Đang xử lý',
    Success: 'Thành công',
    Finish: 'Hoàn thành',
    Paid: 'Đã thanh toán',
    Order: 'Số thứ tự',
    NumberOrder: 'Số thứ tự',
    PrintNumberOrder: 'In số thứ tự',
    Minutes: 'Số phút',
    Action: 'Tác vụ',
    TimeStart: 'Bắt đầu',
    TimeEnd: 'Kết thúc',
    All: 'Tất cả',
    Waiting: 'Đang chờ',
    Cancelled: 'Đã hủy',
    Time: 'Thời gian',
    BillingInformation: 'Thông tin hóa đơn',
    PaymentHistory: 'Lịch sử thanh toán',
    PaymentConfirm: 'Xác nhận thanh toán',
    Receipt: 'Biên lai',
    Package: 'Gói',
    PackageName: 'Tên gói',
    Promotion: 'Khuyến mại',
    TotalPrice: 'Tổng giá',
    Free: 'Miễn phí',
    Room: 'Phòng',
    OriginalPrice: 'Giá gốc',
    DiscountedPrice: 'Giá giảm',
    Type: 'Loại',
    Total: 'Tổng cộng',
    Discount: 'Giảm giá',
    FinalPrice: 'Tổng cộng',
    Code: 'Mã',
    Date: 'Ngày',
    Price: 'Giá',
    AdjustmentPrice: 'Giá điều chỉnh',
    CashAdvance: 'Tiền khách đưa',
    CashReturn: 'Tiền thừa',
    PaymentMethod: 'Phương thức thanh toán',
    MethodDescription: 'Mô tả phương thức',
    PastHistory: 'Lịch sử quá khứ',
    PROMO: 'GIẢM',
    LogOut: 'Đăng xuất',
    Role: 'Vai trò',
    Amount: 'Số lượng',
    TimeOrdered: 'Ngày đặt',
    Top: 'Top',
    RowsPerPage: 'Số hàng',
    CheckInInformation: 'Thông tin check-in',
    Invoice: 'Hóa đơn'
  },
  Revenue: {
    TotalRevenue: 'Tổng doanh thu',
    TotalAdmissions: 'Tổng lượt nhận vào',
    TotalActivePatients: 'Bệnh nhân khả dụng',
    TotalActiveStaffs: 'Nhân viên khả dụng',
    PersonalQueue: 'Hàng chờ cá nhân',
    DailyRevenue: 'Doanh thu theo ngày',
    DailyRevenue7Days: 'Doanh thu trong 7 ngày qua',
    Revenue: 'Doanh thu',
    MonthlyRevenue: 'Doanh thu hàng tháng',
    oflastmonthreached: 'của tháng trước đạt được',
    TotalOf: 'Tổng của',
    revenuethismonth: 'doanh thu tháng này.',
    MonthlyRegistration: 'Đăng ký hàng tháng',
    registerdthismonth: 'đăng ký tháng này.',
    TotalPrice: 'Tổng tiền',
  },
  Roster: {
    EventInformation: 'Thông tin sự kiện',
    TitleEvent: 'Tiêu đề',
    InOffice: 'Trong văn phòng',
    OutOfOffice: 'Ngoài văn phòng',
    Vacation: 'Kỳ nghỉ',
    Absence: 'Vắng mặt',
    Reserved: 'Đã đặt',
    DoesNotRepeat: 'Không lặp lại',
    RepeatEvent: 'Lặp lại',
  },
  Language: {
    English: 'Tiếng Anh',
    VietNam: 'Tiêng Việt'
  },
  Search: {
    EnterToSearch: 'Nhấn enter để tìm',
  },
  Booking: {
    Booking: 'Lịch hẹn',
    BookingList: 'Danh sách đặt lịch',
    BookingTime: 'Giờ hẹn',
    BookingDate: 'Ngày hẹn',
    CreateNewBooking: 'Tạo lịch hẹn',
    BookingCode: 'Mã lịch hẹn',
    BookingInformation: 'Thông tin lịch hẹn',
  },
  Doctor: {
    Doctor: 'Bác sĩ',
    StaffPerform: 'Người thực hiện',
  },
  Clinic: {
    Clinic: 'Phòng khám',
    AllClinics: 'Tất cả phòng khám',
    SelectClinic: 'Chọn phòng khám',
    ClinicName: 'Tên phòng khám',
    ClinicsNetwork: 'Mạng lưới phòng khám',
    DisplayAllClinics: 'Hiển thị tất cả phòng khám từ thương hiệu của bạn',
  },
  Admission: {
    AdmissionType: 'Loại nhận vào',
    Admissions: 'Lượt vào',
  },
  Service: {
    SelectService: 'Chọn dịch vụ',
    Service: 'Dịch vụ',
    ServiceName: 'Tên dịch vụ',
  },
  Calendar: {
    Calendar: 'Lịch hẹn',
    Today: 'Hôm nay',
    Day: 'Ngày',
    Week: 'Tuần',
    Month: 'Tháng',
    Days4: '4 ngày',
  },
  Time: {
    Time: 'Thời gian',
    TimeRange: 'Khung thời gian',
  },
  Patient: {
    Patient: 'Số lượng bệnh nhân',
    Patients: 'bệnh nhân',
    Avatar: 'Ảnh đại diện',
    Create: 'Thêm bệnh nhân',
    SelectPatient: 'Chọn bệnh nhân',
    SearchPatient: 'Tìm kiếm bệnh nhân',
    SearchName: 'Tìm theo tên',
    PatientInformation: 'Thông tin bệnh nhân',
    PatientRegistrationOrder: 'Danh sách hàng chờ',
    PatientName: 'Tên bệnh nhân',
    Name: 'Tên',
    Gender: 'Giới tính',
    DOB: 'Ngày sinh',
    PID: 'Mã bệnh nhân',
    Status: 'Trạng thái',
    Doctor: 'Bác sĩ',
    Room: 'Phòng',
    AID: 'Athena ID',
    Age: 'Tuổi',
    Phone: 'Điện thoại',
    TotalPatient: 'Tổng số bệnh nhân',
    FullName: 'Họ và tên',
    TopPatients: 'Xếp hạng bệnh nhân',
    CalculatedAt: 'Được tính toán từ',
  },
  Payment: {
    CreditCard: 'Thẻ tín dụng',
  },
  EmptyData: {
    Booking: 'Không có lịch hẹn',
    SearchPatient: 'Sử dụng thanh tìm kiếm để tìm bệnh nhân',
    ListEmpty: 'Chưa có dữ liệu!',
    NoBilling: 'Không có hóa đơn được tìm thấy',
    PatientHasNoPayment: 'Bệnh nhân chưa có lịch sử thanh toán!',
    NoResultsMatch: 'Không có dữ liệu',
  },
  DialogConfirm: {
    Confirm: 'Xác nhận',
    CreateBooking: 'Không có lịch hẹn nào được tìm thấy. Bạn có muốn tạo mới một lịch hẹn?',
    DeleteBooking: 'Bạn có chắc chắn muốn xóa lịch hẹn này không?',
    PatientAdmission1: 'Bạn có thực sự muốn nhận vào bênh nhân',
    PatientAdmission2: 'với những dịch vụ đi kèm?',
    UseOccupation: 'Bạn có muốn sử dụng nghề nghiệp này?',
    UseEthicity: 'Do you want to use this ethicity?',
    DeleteThisAdmission: 'Bạn có chắc muốn xóa việc đăng ký này không? <br/> Không thể hoàn tác hành động này.',
    CompleteThisAction: 'Bạn có muốn hoàn tất hành động này?',
    DeleteThisPatient: 'Bạn có chắc là muốn xóa bệnh nhân này không?',
  },
  Button: {
    Create: 'Tạo',
    CreateProfile: 'Lưu hồ sơ',
    SelectAvatar: 'Chọn ảnh đại diện',
    Edit: 'Chỉnh sửa',
    EditProfile: 'Chỉnh sửa hồ sơ',
    FullProfile: 'Xem hồ sơ',
    Accept: 'Chấp nhận',
    Cancel: 'Hủy bỏ',
    Camera: 'Máy ảnh',
    TakePicture: 'Lấy hình ảnh',
    EnterUseEthicity: 'Nhấn Enter để chọn dân tộc này.',
    EnterToSearch: 'Nhấn Enter để tìm...',
    Yes: 'Có',
    No: 'Không',
    Public: 'BHYT',
    Private: 'BH tư nhân',
    EditFreeItem: 'Áp dụng miễn phí',
    Add: 'Thêm',
    SAVECHANGES: 'LƯU',
    CancelPayment: 'Hủy thanh toán',
    Save: 'Lưu',
    Confirm: 'Xác nhận',
    Print: 'In',
    CreateEvent: 'Tạo sự kiện',
    SelectUsers: 'Chọn nhân viên',
    Searching: 'Tìm kiếm',
    AdmitPatient: 'Chấp nhận',
  },
  Toast: {
    SuccessAdmitPatient: "Nhận thành công bệnh nhân",
    CannotGetClinicDetail: "Không thể khi lấy thông tin phòng khám. Vui lòng thử lại sau.",
    ValidationSaveErrorPatientProfile: "Một hoặc nhiều quy tắc xác thực không được chấp nhận. Vui lòng thử lại sau.",
    CannotEditPatientProfile: "Không thể chỉnh sửa thông tin bệnh nhân. Vui lòng thử lại sau.",
    CannotGetListNationality: 'Không thể lấy danh sách quốc gia. Vui lòng thử lại sau.',
    CannotCreateNewEthnicity: 'Không thể tạo mới dân tộc. Vui lòng thử lại sau.',
    CannotGetListEthnicity: 'Không thể lấy danh sách dân tộc. Vui lòng thử lại sau.',
    CannotCreateNewOccupation: 'Không thể tạo mới nghề nghiệp. Vui lòng thử lại sau.',
    CannotGetListOccupation: 'Không thể lấy danh sách nghề nghiệp. Vui lòng thử lại sau.',
    CannotGetListBookings: 'Không thể lấy danh sách lịch hẹn. Vui lòng thử lại sau.',
    AvatarSize: 'Kích thước ảnh đại diện phải nhỏ hơn 2MB',
    MissingRequiredInformation: 'Vui lòng nhập đầy đủ các thông tin bắt buộc.',
    MinimumBooking: 'Cuộc hẹn được đặt trước tối thiểu 1 tiếng. Vui lòng thử lại sau.',
    NoPaymentCanBeFound: 'Không thể tìm thấy khoản thanh toán nào.',
    SelectPaymentOnTheLeftToView: 'Chọn một khoản thanh toán bên trái để xem!',
    CannotGetPaymentSummary: 'Không thể lấy tóm tắt thanh toán. Vui lòng thử lại sau.',
    CannotGetPaymentHistory: 'Không thể lấy lịch sử thanh toán. Vui lòng thử lại sau.',
  },
  Print: {
    VisitStatistics: 'Thống kê lượt khám <br/>',
    Description: 'Nội dung',
    PromotionName: 'Chương trình',
    TotalFee: 'Tổng phí',
    DiscountPrice: 'Giá giảm',
    Change: 'Thay đổi',
    FinalAdjustmentPrice: 'Thực thu',
    Cashier: 'Thu ngân',
    TotalFinalAdjustmentPrice: 'Tổng thực thu',
  },
  Required: {
    RequiredField: 'Trường bắt buộc',
    DateIsRequired: 'Vui lòng chọn ngày',
    NameIsRequired: 'Vui lòng nhập tên',
    EmailIsRequired: 'Vui lòng nhập E-mail',
    Minimum10Digits: 'Tối thiểu 10 chữ số',
    Minimum9Digits: 'Tối thiểu 9 chữ số',
    AcceptNumbersOnly: 'Chỉ chấp nhận số',
    FormatedToDDMMYYY: 'Định dạng thành DD/MM/YYYY',
    EmailNotValid: 'E-mail không hợp lệ',
    InvalidDate: 'Ngày không hợp lệ',
  }
}