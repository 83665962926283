import {
  ApplicationCookieTokenIDName,
  ApplicationLocalStorageUserDataName,
} from "./setting";
import moment from "moment";
import i18n from "@/plugins/i18n";

export const TokenIDCookieName = ApplicationCookieTokenIDName;
export const UserDataLocalStorageName = ApplicationLocalStorageUserDataName;
export const CookieExpireDates = 365;
export const DefaultPaginationLimit = 10;
export const DefaultRequestTimeOutMs = 300000;
export const GenderTypeCodes = {
  Male: 1,
  Female: 2,
};
export const AuditType = {
  Ax: 1,
  Procedure: 2,
  Service: 3,
};
export const BookingType = {
  Deleted: -1,
  Waiting: 1,
  CheckedIn: 2,
  Paid: 3,
};
export const StatusCodes = {
  Deleted: -1,
  Active: 1,
};
export const UserTypeCodes = {
  Admin: 2,
  User: 3,
};
export const AlertType = {
  CheckIn: 1,
  Emergency: 2,
};
export const TABLE_OPTS = {
  page: 1,
  itemsPerPage: 10,
};
export const TimeStringsOptions = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
export const TABLE_ROWS_PER_PAGE = [3, 5, 10, 15, 20, 50];
export default {
  StatusCodes,
  UserTypeCodes,
  TokenIDCookieName,
  UserDataLocalStorageName,
  CookieExpireDates,
  DefaultPaginationLimit,
  DefaultRequestTimeOutMs,
};

export const CRUD_TYPE = {
  Create: 1,
  Read: 2,
  Update: 3,
  Delete: 4,
};

export const BookingAdditionalStatType = {
  Allergy: 1,
  ChronicDisease: 2,
  PersonalBehaviour: 3,
  PastHistory: 4,
  FamilyHistory: 5,
};

export const PaymentMethodType = {
  Cash: 1,
  Card: 2,
  Transfer: 3,
  App: 4,
  Others: 5,
};

export const PaymentStatus = {
  Waiting: 1,
  Deleted: -1,
  Paid: 2,
};

export const EInvoiceStatus = {
  Fail: 1,
  Success: 0,
};

export const TypeEventCalendar = {
  Booking: 1,
  Roster: 2,
  RegisterEvent: 3,
};

export const EventCategory = [
  {
    id: 1,
    bgColor: "#039be5",
    textColor: "#000000",
    text: i18n.t("Roster.InOffice"),
  },
  {
    id: 2,
    bgColor: "#FF9800",
    textColor: "#000000",
    text: i18n.t("Roster.OutOfOffice"),
  },
  {
    id: 3,
    bgColor: "#4285f4",
    textColor: "#ffffff",
    text: i18n.t("Roster.Vacation"),
  },
  {
    id: 4,
    bgColor: "#F44336",
    textColor: "#ffffff",
    text: i18n.t("Roster.Absence"),
  },
  {
    id: 5,
    bgColor: "#616161",
    textColor: "#ffffff",
    text: i18n.t("Roster.Reserved"),
  },
];

export const MomentNearestMinutes = (minutes = 30) => {
  const start = moment();
  const remainder = minutes - (start.minute() % minutes);
  return moment(start).add(remainder, "minutes");
};

export const DefaultCalendarEventDto = {
  id: 0,
  categoryID: EventCategory[0].id,
  title: "",
  description: "",
  startDate: moment().startOf("day").format(),
  endDate: moment().endOf("day").format(),
  timeStart: MomentNearestMinutes(30).format(),
  timeEnd: MomentNearestMinutes(30).add(1, "hours").format(),
  isFullday: false,
  attendeeIDs: [],
  recurrence: null,
};

export const ListDayOfWeek = [
  {
    text: "Sunday",
    value: 1,
  },
  {
    text: "Monday",
    value: 2,
  },
  {
    text: "Tuesday",
    value: 3,
  },
  {
    text: "Wednesday",
    value: 4,
  },
  {
    text: "Thursday",
    value: 5,
  },
  {
    text: "Friday",
    value: 6,
  },
  {
    text: "Saturday",
    value: 7,
  },
];

export const ListWeekOfMonth = [
  {
    text: "1st",
    value: 1,
  },
  {
    text: "2nd",
    value: 2,
  },
  {
    text: "3rd",
    value: 3,
  },
  {
    text: "Last",
    value: 4,
  },
];

export const ListMonthOfYear = [
  {
    text: "January",
    value: 1,
  },
  {
    text: "February",
    value: 2,
  },
  {
    text: "March",
    value: 3,
  },
  {
    text: "April",
    value: 4,
  },
  {
    text: "May",
    value: 5,
  },
  {
    text: "June",
    value: 6,
  },
  {
    text: "July",
    value: 7,
  },
  {
    text: "August",
    value: 8,
  },
  {
    text: "September",
    value: 9,
  },
  {
    text: "October",
    value: 10,
  },
  {
    text: "November",
    value: 11,
  },
  {
    text: "December",
    value: 12,
  },
];

export const CalendarColor = {
  notComing: {
    statusID: "notComming",
    text: "Not Comming",
    color: "#de350b",
  },
  late: {
    statusID: "late",
    text: "Late",
    color: "#ff991f",
  },
  inComming: {
    statusID: "inComming",
    text: "In Comming",
    color: "#0052cc",
  },
};
