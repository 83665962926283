import store from '../store'

const IsAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next('/login')
}

const IsNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/dashboard')
}

export default {
    IsAuthenticated,
    IsNotAuthenticated,
}