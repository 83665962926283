
import Vue from 'vue';

import SignalRSocket from "@/plugins/socket";

const commonSocket = new SignalRSocket("common");
commonSocket.connect();

// Create a global variable mixins to store this list of components
Vue.mixin({
  data: function () {
    return {
      sockets: {
        common: commonSocket.connection,
      }
    }
  }
});