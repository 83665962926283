import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

import AuthMiddleware from "./middlewares/authen";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "*",
      redirect: "onlinebooking",
    },
    {
      path: "/",
      redirect: "onlinebooking",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "onlinebooking",
          path: "onlinebooking",
          component: () => import("@/views/OnlineBooking/OnlineBooking"),
          beforeEnter: AuthMiddleware.IsAuthenticated,
        },
        {
          name: "patientregistration",
          path: "patientregistration",
          component: () =>
            import("@/views/PatientRegistration/PatientRegistration"),
          beforeEnter: AuthMiddleware.IsAuthenticated,
        },
        {
          name: "billing",
          path: "billing",
          component: () => import("@/views/Billing/BillingQueue"),
          beforeEnter: AuthMiddleware.IsAuthenticated,
        },
        {
          name: "report",
          path: "report/",
          component: () => import("@/views/Report/Report"),
          beforeEnter: AuthMiddleware.IsAuthenticated,
          children: [
            {
              name: "commonReport",
              path: "common-report",
              component: () =>
                import("@/views/Report/components/commonReport/CommonPayment"),
            },
            {
              name: "paymentReportByDateAndClinic",
              path: "payment-report-date-and-clinic",
              component: () =>
                import(
                  "@/views/Report/components/detailOrderPaymentReport/DetailOrderPaymentReportContainer"
                ),
            },
          ],
        },
        {
          name: "dashboard",
          path: "dashboard",
          component: () => import("@/views/Dashboard/Dashboard"),
          beforeEnter: AuthMiddleware.IsAuthenticated,
        },
        {
          name: "roster",
          path: "roster",
          component: () => import("@/views/Roster/Roster"),
          beforeEnter: AuthMiddleware.IsAuthenticated,
        },
      ],
    },
    {
      path: "/glass-print/:episodeRecordID/:patientID",
      component: () => import("@/components/commonComponents/Print/GlassPrint"),
      beforeEnter: AuthMiddleware.IsAuthenticated,
    },
    {
      path: "/medicine-print/:episodeRecordID/:patientID",
      component: () =>
        import("@/components/commonComponents/Print/MedicinePrint"),
      beforeEnter: AuthMiddleware.IsAuthenticated,
    },
    {
      path: "/payment-print",
      component: () =>
        import("@/components/commonComponents/Payment/PaymentPrint"),
      beforeEnter: AuthMiddleware.IsAuthenticated,
    },
    {
      path: "/orderNumber-print/:patientID/:episodeRecordID/:number",
      component: () =>
        import("@/components/commonComponents/Print/OrderNumberPrint"),
      beforeEnter: AuthMiddleware.IsAuthenticated,
    },
    {
      path: "/report-print",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      beforeEnter: AuthMiddleware.IsAuthenticated,
      children: [
        {
          name: "payment",
          path: "payment",
          component: () =>
            import("@/views/Report/components/commonReport/PaymentPrint"),
          beforeEnter: AuthMiddleware.IsAuthenticated,
        },
      ],
    },
    {
      path: "/login",
      component: () => import("@/views/authentication/FullLogin"),
      beforeEnter: AuthMiddleware.IsNotAuthenticated,
    },
    {
      path: "/notfound",
      component: () => import("@/views/authentication/Error"),
    },
    {
      path: "/queue",
      redirect: "/queue/overview",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "overview",
          path: "/queue/overview",
          component: () => import("@/views/Queue/OverviewQueue"),
        },
        {
          name: "overviewid",
          path: "/queue/overviewid/:episodeRecordID",
          component: () => import("@/views/Queue/OverviewQueue"),
        },
        {
          name: "personal queue",
          path: "/queue/personal",
          component: () => import("@/views/Queue/PersonalQueue"),
        },
      ],
    },
    {
      path: "/test-fast",
      redirect: "/",
      component: () => import("@/layouts/full-layout/Layout"),

      children: [
        {
          name: "fast_test",
          path: "/",
          component: () => import("@/views/FastTest/FastTest"),
        },
      ],
    },
    {
      path: "/booking-calendar",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "BookingCalendar",
          path: "/",
          component: () => import("@/views/OnlineBooking/BookingCalendar"),
        },
      ],
    },
    {
      path: "/invoice",
      component: () => import("@/layouts/full-layout/Layout"),
      beforeEnter: AuthMiddleware.IsAuthenticated,
      redirect: "BusinessInfo",
      children: [
        {
          name: "InvoicePage",
          path: "/",
          redirect: "BusinessInfo",
          component: () => import("@/views/Invoice/InvoiceBilling"),
          children: [
            {
              name: "BusinessInfo",
              path: "business-info",
              component: () =>
                import(
                  "@/views/Invoice/components/BusinessInfor/BusinessInformation"
                ),
            },
            {
              name: "CreateNewInvoice",
              path: "create-new-invoice/:summaryPaymentID?",
              component: () =>
                import(
                  "@/views/Invoice/components/CreateInvoice/CreateInvoiceContain"
                ),
            },
            {
              name: "CreatePatchInvoice",
              path: "create-batch-invoice",
              component: () =>
                import(
                  "@/views/Invoice/components/CreateInvoice/CreateBatchInvoice"
                ),
            },
            {
              name: "GetListInvoiceByFormAndSerial",
              path: "get-list-invoice-by-form-serial",
              component: () =>
                import(
                  "@/views/Invoice/components/ListInvoiceByFormSerial/ListInvoiceContainer"
                ),
            },
            {
              name: "ViewDetailInvoiceByInvoiceGUID",
              path: "view-invoice-detail-by-invoice-guid/:invoiceGUID?",
              component: () =>
                import(
                  "@/views/Invoice/components/InvoiceDetail/InvoiceDetailContainer"
                ),
            },
          ],
        },
      ],
    },
  ],
});
