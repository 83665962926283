export default {
  Common: {
    Allergies: 'Allergies',
    Address: 'Address',
    Checking: 'Checking...',
    ViewChecking: 'View Checking',
    ChiefComplains: 'Chief Complains',
    ChronicDiseases: 'Chronic Diseases',
    CurrentInsuranceCard: 'Current Insurance Card',
    Country: 'Country',
    DOB: 'Day of birth*',
    Email: 'Email',
    Ethnicity: 'Ethnicity',
    FamilyHistory: 'Family History',
    FullName: 'Full Name*',
    Status: 'Status',
    SocialInsuranceInformation: 'Social Insurance Information',
    HealthInsurrance: 'Health Insurrance',
    Insurrance: 'Insurrance',
    HistoryExamination: 'History Examination',
    IdentificationNumber: 'Identification Number',
    Insurance: 'Insurance',
    InsuranceType: 'Insurance Type',
    InsuranceNumber: 'Insurance Number',
    InsuranceCompany: 'Insurance Company',
    MedicalConditionInformation: 'Medical Condition Information',
    Note: 'Note',
    Occupation: 'Occupation',
    PersonalBehaviour: 'Personal Behaviour',
    PersonalInformation: "Personal Information",
    PersonalHistory: 'Personal History',
    RelativeInformation: 'Relative Information',
    RelativeMemberName: "Relative's Member Name",
    Relationship: 'Relationship',
    TransferAnotherInsuranceCard: 'Transfer Another Insurance Card',
    PatientRegistration: 'Patient Registration',
    OnlineBooking: 'Online Booking',
    PatientQueue: 'Patient Queue',
    BillingQueue: 'Billing Queue',
    Report: 'Report',
    Dashboard: 'Dashboard',
    DoctorRoster: 'Doctor Roster',
    LastVisitsOf: 'Last Visits of',
    LastVisit: 'Last Visit',
    PrimaryDiagnosis: 'Primary Diagnosis',
    Male: 'Male',
    Female: 'Female',
    Controller: 'Controller',
    QueueInformation: 'Queue information',
    InProcess: 'In process',
    Success: 'Success',
    Finish: 'Finish',
    PrintNumberOrder: 'Print Number Order',
    Paid: 'Paid',
    Order: 'Order',
    NumberOrder: 'Number',
    Minutes: 'Minutes',
    Action: 'Actions',
    TimeStart: 'Time Start',
    TimeEnd: 'Time End',
    All: 'All',
    Waiting: 'Waiting',
    Cancelled: 'Cancelled',
    Time: 'Time',
    BillingInformation: 'Billing Information',
    PaymentHistory: 'Payment History',
    PaymentConfirm: 'Payment Confirm',
    Receipt: 'Receipt',
    Package: 'Package',
    PackageName: 'Package Name',
    Promotion: 'Promotion',
    TotalPrice: 'Total Price',
    Free: 'Free',
    Room: 'Room',
    OriginalPrice: 'Original Price',
    DiscountedPrice: 'Discounted Price',
    Type: 'Type',
    Total: 'Total',
    Discount: 'Discount',
    FinalPrice: 'Final Price',
    Code: 'Code',
    Date: 'Date',
    Price: 'Price',
    AdjustmentPrice: 'Adjustment Price',
    CashAdvance: 'Cash advance',
    CashReturn: 'Cash return',
    PaymentMethod: 'Payment Method',
    MethodDescription: 'Method Description',
    PastHistory: 'Past History',
    PROMO: 'PROMO',
    LogOut: 'Log out',
    Role: 'Role',
    Amount: 'Amount',
    TimeOrdered: 'TimeOrdered',
    Top: 'Top',
    RowsPerPage: 'Rows per page',
    CheckInInformation: 'Check-in Information',
    Invoice: 'Invoice'
  },
  Revenue: {
    TotalRevenue: 'Total Revenue',
    TotalAdmissions: 'Total Admissions',
    TotalActivePatients: 'Total Active Patients',
    TotalActiveStaffs: 'Total Active Staffs',
    PersonalQueue: 'Personal Queue',
    DailyRevenue: 'Daily Revenue',
    DailyRevenue7Days: 'Daily revenue of the last 7 days',
    Revenue: 'Revenue',
    MonthlyRevenue: 'Monthly Revenue',
    oflastmonthreached: 'of last month reached',
    TotalOf: 'Total of',
    revenuethismonth: 'revenue this month.',
    MonthlyRegistration: 'Monthly Registration',
    registerdthismonth: 'registerd this month.',
    TotalPrice: 'Total price',
  },
  Roster: {
    EventInformation: 'Event Information',
    TitleEvent: 'Title event',
    InOffice: 'In Office',
    OutOfOffice: 'Out Of Office',
    Vacation: 'Vacation',
    Absence: 'Absence',
    Reserved: 'Reserved',
    DoesNotRepeat: 'Does not repeat',
    RepeatEvent: 'Repeat event',
  },
  Language: {
    English: 'English',
    VietNam: 'Vietnamese'
  },
  Search: {
    EnterToSearch: 'Press enter to seeking',
  },
  Booking: {
    Booking: 'Schedule',
    BookingList: 'Bookings List',
    BookingTime: 'Booking Time',
    BookingDate: 'Booking Date',
    CreateNewBooking: 'New Booking',
    BookingCode: 'Booking Code',
    BookingInformation: 'Booking Information',
  },
  Doctor: {
    Doctor: 'Doctor',
    StaffPerform: 'Staff Perform',
  },
  Clinic: {
    Clinic: 'Clinic',
    AllClinics: 'All Clinics',
    SelectClinic: 'Select Clinic',
    ClinicName: 'Clinic Name',
    ClinicsNetwork: 'Clinics Network',
    DisplayAllClinics: 'Display all clinics from your branding',
  },
  Admission: {
    AdmissionType: 'Admission Type',
    Admissions: 'Admissions',
  },
  Service: {
    SelectService: 'Select Service',
    Service: 'Service',
    ServiceName: 'Service Name',
  },
  Calendar: {
    Calendar: 'Schedule',
    Today: 'Today',
    Day: 'Day',
    Week: 'Week',
    Month: 'Month',
    Days4: '4 days',
  },
  Time: {
    Time: 'Time',
    TimeRange: 'Time range',
  },
  Patient: {
    Patient: 'Patients',
    Patients: 'Patients',
    Avatar: 'Avatar',
    Create: 'New Patient',
    SelectPatient: 'Select Patient',
    SearchPatient: 'Searching Patient',
    SearchName: 'Search Name',
    PatientInformation: 'Patient Information',
    PatientRegistrationOrder: 'List patient queue',
    PatientName: 'Patient Name',
    Name: 'Name',
    Gender: 'Gender',
    DOB: 'DOB',
    PID: 'Patient ID',
    AID: 'Athena ID',
    Age: 'Age',
    Phone: 'Phone',
    TotalPatient: 'Total Patient',
    FullName: 'Full Name',
    TopPatients: 'Top Patients',
    CalculatedAt: 'Calculated at',
  },
  Payment: {
    CreditCard: 'Credit card',
  },
  EmptyData: {
    Booking: 'No booking can be found',
    SearchPatient: 'Search patients using search textbox',
    ListEmpty: 'List is Empty!',
    NoBilling: 'No billing can be found',
    PatientHasNoPayment: 'Patient has no payment history yet!',
    NoResultsMatch: 'No results match',
  },
  DialogConfirm: {
    Confirm: 'Confirmation',
    CreateBooking: 'No booking can be found! Do you want to create new booking?',
    DeleteBooking: 'Are you sure you want to delete this booking?',
    PatientAdmission1: 'Do you really want to admit patient',
    PatientAdmission2: 'with the following services?',
    UseOccupation: 'Do you want to use this occupation?',
    UseEthicity: 'Do you want to use this ethicity?',
    DeleteThisAdmission: 'Are you sure you want to delete this admission? <br/> This action cannot be reverted.',
    CompleteThisAction: 'Are you want to complete this action?',
    DeleteThisPatient: 'Are you sure you want to delete this patient?',
  },
  Button: {
    Create: 'Create',
    CreateProfile: 'Save Profile',
    SelectAvatar: 'Select Avatar',
    Edit: 'Edit',
    EditProfile: 'Edit Profile',
    FullProfile: 'Full Profile',
    Accept: 'Accept',
    Cancel: 'Cancel',
    Camera: 'Camera',
    TakePicture: 'Take Picture',
    EnterUseEthicity: 'Press enter to use this ethicity',
    EnterToSearch: 'Enter to search...',
    Yes: 'Yes',
    No: 'No',
    Public: 'Public',
    Private: 'Private',
    EditFreeItem: 'Edit free items',
    Add: 'Add',
    SAVECHANGES: 'SAVE CHANGES',
    CancelPayment: 'Cancel payment',
    Save: 'Save',
    Confirm: 'Confirm',
    Print: 'Print',
    CreateEvent: 'Create event',
    SelectUsers: 'Select users',
    Searching: 'Searching',
    AdmitPatient: 'ADMIT PATIENT',
  },
  Toast: {
    SuccessAdmitPatient: "Successfully admitted patient",
    CannotGetClinicDetail: "Cannot get clinic detail. Please try again later.",
    ValidationSaveErrorPatientProfile: "One or more validation rules are not accepted. Please try again",
    CannotEditPatientProfile: "Cannot edit patient profile. Please try again later.",
    CannotGetListNationality: 'Cannot get list nationality. Please try again later.',
    CannotCreateNewEthnicity: 'Cannot create new ethicity. Please try again later',
    CannotGetListEthnicity: 'Cannot get list ethnicity. Please try again later.',
    CannotCreateNewOccupation: 'Cannot create new occupation. Please try again later',
    CannotGetListOccupation: 'Cannot get list occupation. Please try again later.',
    CannotGetListBookings: 'Cannot get list bookings. Please try again later!',
    AvatarSize: 'Avatar size should be less than 2 MB!',
    MissingRequiredInformation: 'Missing required information! Please try again.',
    MinimumBooking: 'Minimum booking in advance is 1 hours. Please try again',
    NoPaymentCanBeFound: 'Oops! No payment can be found!',
    SelectPaymentOnTheLeftToView: 'Chọn một khoản thanh toán ở bên trái để xem!',
    CannotGetPaymentSummary: 'Cannot get payment summary. Please try again later!',
    CannotGetPaymentHistory: 'Cannot get payment history. Please try again later!',
  },
  Print: {
    VisitStatistics: 'Visit Statistics <br/>',
    Description: 'Description',
    PromotionName: 'Promotion Name',
    TotalFee: 'Total fee',
    DiscountPrice: 'Discount Price',
    Change: 'Change',
    FinalAdjustmentPrice: 'Final Adjustment Price',
    Cashier: 'Cashier',
    TotalFinalAdjustmentPrice: 'Total Final Adjustment Price',
  },
  Required: {
    RequiredField: 'Required field',
    DateIsRequired: 'Date is required',
    NameIsRequired: 'Name is required',
    EmailIsRequired: 'Email is required',
    Minimum10Digits: 'Minimum 10 digits',
    Minimum9Digits: 'Minimum 9 digits',
    AcceptNumbersOnly: 'Accept numbers only',
    FormatedToDDMMYYY: 'Formated to DD/MM/YYYY',
    EmailNotValid: 'E-mail not valid',
    InvalidDate: 'Invalid date',
  }
}