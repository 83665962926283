import HttpClient from '../plugins/httpClient'
import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'
import moment from "moment"

var httpClient = new HttpClient(ApiGateway.Booking)

export default {
    searchBookings: async (keySearch = "", timeStart = "", timeEnd = "", clinicID = "", statusID = 1, page = 1, limit = AppConstant.DefaultPaginationLimit) => {
        var query = {
            keySearch,
            timeStart,
            timeEnd,
            statusID,
            clinicID,
        }
        var headers = {
            page,
            limit,
        }
        return httpClient.get(`/Booking/Search`, query, headers);
    },
    getBookingDetail(bookingID) {
        var query = {
            bookingID
        }
        return httpClient.get(`/Booking/Get/{bookingID}`, query);
    },
    async create(booking = {}) {
        booking.timeStart = moment(booking.timeStart).format()
        booking.timeEnd = moment(booking.timeEnd).format()
        return httpClient.post(`/Booking/Create`, {}, booking);
    },
    getBookingByPatient: (patientID, timeStart, timeEnd) => {
        var query = {
            patientID,
            timeStart,
            timeEnd
        };
        return httpClient.get(`/Booking/SearchByPatient`, query);
    },
    updatePatientBookingInfoByID(bookingID, booking) {
        var query = {
            bookingID
        }
        booking.timeStart = moment(booking.timeStart).format()
        booking.timeEnd = moment(booking.timeEnd).format()
        return httpClient.put(`/Booking/Update/${bookingID}`, query, booking);
    },
    deleteBooking(bookingID) {
        var query = {
            bookingID
        }
        return httpClient.put(`/Booking/Delete/{bookingID}`, query)
    },
    removeBooking(bookingID) {
        var query = {
            bookingID
        }
        return httpClient.put(`/Booking/Remove/{bookingID}`, query)
    },

    checkBookingWithTimeSlot(clinicID, dateAndStartTime) {
        var query = {
            clinicID,
            dateAndStartTime,
        }
        return httpClient.get(`/Booking/EmptyBookingTimeZone`, query);
    }
}