import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@aspnet/signalr";
import { sleep } from "@/plugins/helper";
import { ApiGateway } from "@/plugins/gateway";

export default class SignalRSocket {
  // New Signal-R connection instance with options
  // This will innitiate a connection config without actually connect
  constructor(hubUrl) {
    this.hubUrl = hubUrl;
    this.connection = new HubConnectionBuilder()
      .withUrl(`${ApiGateway.Signal}/${hubUrl}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Debug)
      .build();

    this.connection.onclose(() => this.connect());
  }
  // connect the socket and wait for the result to success
  // await this.connect() => return this.connection when connection success
  connect() {
    return new Promise((resolve) => {
      this.connection
        .start()
        .then(() => resolve(this.connection))
        .catch(async (err) => {
          console.error("Failed to connect with hub", this.hubUrl);
          console.error(err);
          console.log("Reconnecting every 5000 seconds");
          await sleep(5000000);
          this.connect();
        });
    });
  }
}
