import Vue from 'vue'
import Vuex from 'vuex'

import Drawer from './stores/drawer'
import Auth from './stores/auth'
import Notify from './stores/notify'
import BookingCalendar from './stores/bookingCalendar'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        Drawer,
        Auth,
        Notify,
        BookingCalendar
    },
})