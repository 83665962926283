import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "@/lang/en";
import vn from "@/lang/vn";
import { DefaultLanguageLocale } from "./setting";

Vue.use(VueI18n);

const messages = {
  en: en,
  vn: vn,
};

const i18n = new VueI18n({
  locale: localStorage.getItem("LOCATE_LANGUAGE") || DefaultLanguageLocale,
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
});

export default i18n;
