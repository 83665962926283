export const ApiGateway = {
  Authen: process.env.VUE_APP_API,
  Hr: process.env.VUE_APP_API,
  Pas: process.env.VUE_APP_API,
  Booking: process.env.VUE_APP_API,
  Emr: process.env.VUE_APP_API,
  Signal: process.env.VUE_APP_API,
  AiFunctions: process.env.VUE_APP_AI_FUNCTIONS,
  Orthanc: process.env.VUE_APP_API,
};
