const state = () => ({
  message: '',
  type: 'error',
  count: 0,
  show: false
})

const getters = {
}

const mutations = {
  SET_MESSAGE(state, payload) {
    if (state.message === payload) {
      state.count++
    } else {
      state.count = 0
    }

    state.message = payload
  },
  SET_TYPE(state, payload) {
      state.type = payload
  },
  SET_COUNT(state, payload) {
      state.count = payload
  },
  SET_SHOW(state, payload) {
    state.show = payload
  },
}

const actions = {
  notifyAction ({ commit }, message) {
    commit('SET_MESSAGE', message)
    commit('SET_SHOW', true)
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
}